<template>
  <div class="g col-12 col-lg-6 c">
    <br>
    <div class="card">
        <div class="card-body">
            <div class="form-group">
              <h5>الرسالة</h5>
              <textarea class="form-control" v-model="message" @keyup="messageChanged()" @change="messageChanged()" rows="6" placeholder="اكتب الرسالة هنا..."></textarea>
            </div>
            <div class="col-12 g">
              <div class="card card-body border">
                <table class="table table-hover table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        اسم الطالب
                      </td>
                      <td>
                        {name}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        الفصل
                      </td>
                      <td>
                        {class}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        الايميل
                      </td>
                      <td>
                        {email}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        الجوال
                      </td>
                      <td>
                        {phone}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
              <div class="form-group g">
                <span>عرض فصل معين</span>
                <select class="form-control" v-model="selected_classroom" @change="viewClass()">
                  <option value="all">الكل</option>
                  <option v-for="o in classrooms" :key="o" :value="o">{{ o }}</option>
                </select>
              </div>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="send()">
                    <i class="fa fa-paper-plane"></i>
                    ارسال الآن
                </button>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <button class="btn btn-primary btn-sm" @click="csv()">
                <i class="fa fa-download"></i>
                تصدير أرقام الجوالات &nbsp;
                <small>( لرفعها على جهات اتصال جوجل )</small>
            </button>
            &nbsp;&nbsp;
            <button class="btn btn-info btn-sm" @click="print()">
                <i class="fa fa-print"></i>
                طباعة بيانات الطلاب
            </button>
            &nbsp;&nbsp;
            <button class="btn btn-success btn-sm" @click="excel()">
                <i class="fa fa-file"></i>
                تصدير بيانات الطلاب
            </button>
            <br><br>
            <table style="display:none" id="headerTable">
              <thead>
                <th>
                  م
                </th>
                <th>
                 رقم الهوية
                </th>
                <th>
                  اسم الطالب
                </th>
                <th>
                  اسم المستخدم
                </th>
                <th>
                  الجوال
                </th>
                <th>
                  الفصل
                </th>
              </thead>
              <tbody>
                <template v-for="(student, c) in students">
                  <tr :key="c" v-if="student.show">
                      <td>{{ c + 1 }}</td>
                      <td>{{ student.number }}</td>
                      <td>{{ student.name }}</td>
                      <td>{{ student.email }}</td>
                      <td>{{ student.phone }}</td>
                      <td>{{ student.classroom }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
            <div class="col-12 table-responsive">
                <table class="table table-hover table-stipred table-sm">
                    <thead>
                        <th>
                            الطالب
                        </th>
                        <th>
                            الجوال
                        </th>
                        <th>
                            الفصل
                        </th>
                        <th>
                            الرسالة
                        </th>
                    </thead>
                    <tbody id="students-messages">
                        <template v-for="(student, c) in students">
                        <tr v-if="student.show" :key="c">
                            <td>{{ student.name }}</td>
                            <td>{{ student.phone }}</td>
                            <td>{{ student.classroom }}</td>
                            <td><textarea class='form-control msg' readonly v-model="student.message"></textarea></td>
                        </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <b-modal id="modal-students-ghiab-manual" size="lg" title="ارسال رسائل لاولياء الامور الغائبين" hide-footer>
        <div class="col-12 text-center g">
            <div class="alert alert-success text-center g" v-if="view_archive">
                <img src="/files/done.gif" style="border-radius: 50%">
                <br>
                <br>
                تم استلام الرسائل بنجاح وجاري ارسالها الآن٫<br>
                يمكنك متابعة الارسال <a :href="`/archive?school_id=${school_id}`" target="_blank"><strong><u>من هنا</u></strong></a>
            </div>
            <template v-if="!loading && !view_archive">
                <button class="btn btn-lg btn-primary" v-if="selected == 'sms' || selected == 'whatsapp-api'" @click="sendNow(selected)">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
                <div class="col-12 text-center alert alert-success" v-if="selected == 'whatsapp'" id='remove-me-wa'>
                    <h4>
                        لتتمكن من ارسائل رسائل الواتسآب، يجب عليك تحميل أداة الخدمات الاضافية من سوق كروم.
                        <br><br>
                        <a href="https://bit.ly/3yAC50a" target="_blank" class="btn btn-success">
                            <i class="fa fa-download"></i>
                            إضغط هنا لتثبيت الاداة
                        </a>
                        <br>
                        وبعد تثبيتها قم بإعادة تحديث هذه الصفحة.
                    </h4>
                </div>
                <button class="btn btn-lg btn-primary" v-if="selected == 'whatsapp'" @click="sendNow(selected)" id="brmja-send">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
            </template>
            <template v-if="loading && !view_archive">
                <button class="btn btn-lg btn-primary disabled">
                    جاري الإرسال...
                </button>
            </template>
        </div>
        <div class="col-12 table-responsive">
            <table class="table table-sm table-hover" id="brmja-messages-table">
                <thead>
                    <th>
                        الجوال
                    </th>
                    <th>
                        الطالب
                    </th>
                    <th>
                        الرسالة
                    </th>
                </thead>
                <tbody id="students-messages">
                    <template v-for="(student, c) in students">
                      <tr v-if="student.show" :key="c">
                          <td>{{ student.phone }}</td>
                          <td>{{ student.name }}</td>
                          <td><textarea class='form-control msg' readonly v-model="student.message"></textarea></td>
                      </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </b-modal>
    <button
      class="dnone"
      id="students-ghiab-manual"
      @click="sendModal()"
      v-b-modal.modal-students-ghiab-manual
    ></button>
  </div>
</template>

<script>
export default {
    data(){
        return {
            type: window.location.href.split("type=")[1].split("&")[0],
            school_id: window.location.href.split("school_id=")[1].split("&")[0],
            message: `اسم الطالب: {name}
الفصل: {class}
الجوال: {phone}
الايميل: {email}`,
            students: [],
            loading: false,
            view_archive: false,
            classrooms: [],
            selected_classroom: "all",
            selected: ""
        }
    },
    created(){
      var g = this;
      var school_id = g.school_id
      setTimeout(() => {
        $("#date").html(new Date().toISOString().split('T')[0])
        $("body").append(`
          <textarea class='get-local-storage' name='${g.type}_students_${school_id}'></textarea>
        `)
        var i1 = setInterval(() => {
            if($(`.get-local-storage-value[name='${g.type}_students_${school_id}']`).length){
              clearInterval(i1)
              g.students = JSON.parse($(`.get-local-storage-value[name='${g.type}_students_${school_id}']`).val())
              var v = []
              g.students.forEach(element => {
                element.show = true
                element.message = ""
                if(!g.classrooms.includes(element.classroom)){
                  g.classrooms.push(element.classroom)
                }
                v.push(element)
              });
              g.students = v
              g.messageChanged()
            }
        }, 10);
      }, 500);
    },
    methods: {
      excel(){
            var location = 'data:application/vnd.ms-excel;base64,';
            var excelTemplate = '<html> ' +
                '<head> ' +
                '<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/> ' +
                '</head> ' +
                '<body> ' +
                document.getElementById("headerTable").outerHTML +
                '</body> ' +
                '</html>'
            window.location.href = location + btoa(unescape(encodeURIComponent((excelTemplate))));
            setTimeout(() => {
                window.location = window.location.href
            }, 1000);
      },
        csv(){
          var g = this;
          var arr = [];
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, "0");
          var mm = String(today.getMonth() + 1).padStart(2, "0");
          var yyyy = today.getFullYear();
          today = dd + "/" + mm + "/" + yyyy;
          $.each(this.students, function (i, student) {
              arr.push(
                  {
                      "Name": student.name,
                      "Given Name": "",
                      "Additional Name": "",
                      "Family Name": "",
                      "Yomi Name": "",
                      "Given Name Yomi": "",
                      "Additional Name Yomi": "",
                      "Family Name Yomi": "",
                      "Name Prefix": "",
                      "Name Suffix": "",
                      "Initials": "",
                      "Nickname": "",
                      "Short Name": "",
                      "Maiden Name": "",
                      "Birthday": "",
                      "Gender": "",
                      "Location": "",
                      "Billing Information": "",
                      "Directory Server": "",
                      "Mileage": "",
                      "Occupation": "",
                      "Hobby": "",
                      "Sensitivity": "",
                      "Priority": "",
                      "Subject": "",
                      "Notes": "number: +" + student.phone,
                      "Language": "",
                      "Photo": "",
                      "Group Membership": "تم الاستيراد في " + today + " ::: * myContacts",
                      "Phone 1 - Type": "",
                      "Phone 1 - Value": "+" + student.phone
                  }
              )
          });
          const array = [Object.keys(arr[0])].concat(arr)

  var csvContent =  array.map(it => {
    return Object.values(it).toString()
  }).join('\n')
          var encodedUri = "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);

var link = document.createElement("a");
link.setAttribute("href", encodedUri);
link.setAttribute("download", "naqar_"+today+".csv");
document.body.appendChild(link); // Required for FF

link.click();
        },
        refresh(){
          this.students = JSON.parse(JSON.stringify(this.students))
        },
        messageChanged(){
          var g = this;
          g.students = g.students.map(function(student){
            student.message = g.message.replace("{name}", student?.name).replace("{class}", student?.classroom).replace("{phone}", student?.phone).replace("{email}", student?.email)
            return student;
          })
          g.refresh()
        },
        send(){
            $("#c-subscribe-ended").click()
            $("#after-send-settings-click").val("#students-ghiab-manual")
        },
        viewClass(){
          var g = this;
          var arr = []
          g.students.forEach(function(student){
            student.show = false
            if(g.selected_classroom == "all" || g.selected_classroom == student.classroom){
              student.show = true
            }
            arr.push(student)
          })
          g.students = arr
        },
        sendModal(){
          var g =this;
          if(localStorage.getItem('send')){
              g.selected = JSON.parse(localStorage.getItem('send')).selected
          }
          g.messageChanged()
        },
        sendNow(method){
            var g = this;
            var c = []
            g.students.forEach(function(s){
              if(s.show){
                c.push({
                  phone: s.phone,
                  message: s.message
                })
              }
            })
            g.loading = true
            $.post(api + '/api/messages/send', {
                school_id: g.school_id,
                arr: JSON.stringify(c),
                method: method,
                settings: localStorage.getItem('send'),
                type: 'students'
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.view_archive = true
                }else{
                    alert(response.response)
                }
            })
            .fail(function(){
                g.loading = false
                alert('حدث خطأ')
            })
        },
        print(){
          var g = this, gg = "", index = 0;
            var newWin= window.open("");
            g.students.forEach(function(student){
              if(student.show){
                index++
                gg = gg + `
                <tr>
                  <td>
                    ${index}
                  </td>
                  <td>
                    ${student.name}
                  </td>
                  <td>
                    ${student.phone}
                  </td>
                  <td>
                    ${student.email}
                  </td>
                  <td>
                    ${student.classroom}
                  </td>
                </tr>
                `
              }
            })
            var outerHTML = `
            <table>
              <thead>
                <th>
                م
                </th>
                <th>
                الاسم
                </th>
                <th>
                الجوال
                </th>
                <th>
                الايميل
                </th>
                <th>
                الفصل
                </th>
              </thead>
              <tbody>${gg}</tbody>
            </table>
            `
            newWin.document.write(outerHTML + `<style>
            #headerTable{
                  position: relative;
            top: -100px;
            width: 90%;
            margin: 0px auto;
            }
                body{
                    direction: rtl;
                    text-align: right
                }
                thead{
                    background: #eee;
                }
                table, td, th {
                border: 1px solid;
                white-space: nowrap;
                text-align: center
                }
                tr:nth-child(even) {
                    background-color: #f2f2f2;
                }
                table {
                width: 100%;
                border-collapse: collapse;
                }
                button{
                    display:none
                }
                </style>`);
            newWin.document.close();
            newWin.print();
        }
    }
}
</script>

<style>

</style>